<template>
    <div :class='{"kt-spinner fix-spinner-index kt-spinner--sm kt-spinner--brand kt-spinner--right kt-spinner--input" : isPhoneLoading }'>
        <div class="kt-input-icon kt-input-icon--left country-input" :class="isCountryComponent ? 'input-group': ''">
            <template v-if="isCountryComponent">
                <div class="input-group-prepend" :class="errorClass ? 'border-right-none': ''">
                    <vue-country-code
                        @onSelect="onSelect"
                        :preferredCountries="['id']" ></vue-country-code> 
                </div>
            </template>
            <template v-else>
                <span class="kt-input-icon__icon position-plus-country">
                    <span style="padding-bottom: 1px;">
                        +
                    </span>
                </span>
            </template>
            <input
                type="text"
                class="form-control"
                v-model="phoneNumber"
                :name="name"
                placeholder="Nomor Telepon"
                :class="{'is-invalid': errorClass, 'pl-1' : isCountryComponent, 'pl-25' :  !isCountryComponent}"
                :disabled="isDisabled"
                style="margin-left: 1px;"
            />
        </div>
        <small
            v-if="errorText"
            class="text text-danger"
            >{{ errorText }}</small>
    </div>
  </template>
  
  <script>
  import VueCountryCode from "vue-country-code";

  export default {
    props: {
        isPhoneLoading: {type: Boolean, default: false},
        isCountryComponent: {type: Boolean, default: false},
        errorText: { type: String,default: ''},
        errorClass: { type: String, default: ''},
        name: String,
        value: {type: null,default: null},
        isDisabled:{type: Boolean, default: false}
    },
    components:{
        VueCountryCode
    },
    watch: {
        phoneNumber(val) {
            const inputText = val.replace(this.numberCountry, '')
            this.$emit('input', `${this.numberCountry}${inputText}`)
        }
    },
    data () {
        return {
            phoneNumber: '',
            numberCountry: '',
            isEmit: false
        }
    },
    mounted() {
        if(!this.isCountryComponent) {
            this.phoneNumber = this.value
        }
    },
    methods: {
        onSelect({ dialCode}) {
            const element = document.getElementsByClassName('vue-country-select')[0]
            const current = element.getElementsByClassName('current')[0]
            if(current.getElementsByClassName('name-country').length > 0) {
                const span = document.getElementsByClassName('name-country')[0]
                span.innerHTML = `+${dialCode}`
            } else {
                const node = document.createElement('span')
                node.classList.add('name-country')
                node.appendChild(document.createTextNode(`+${dialCode}`))
                current.appendChild(node)
            }
            const tempCode = this.numberCountry
            const tempPhone = this.phoneNumber
            const inputText = tempPhone.replace(tempCode, '')
            this.numberCountry = dialCode
            if(dialCode != '62') {
                this.isEmit = true
                this.$emit('sweetAlertAction', true)
            }
            if(this.isEmit) {
                this.$emit('input', `${this.numberCountry}${inputText}`)
            }
        },
    }
  }
  </script>
  
  <style scoped>
    .border-right-none{
        border: 1px solid #fd397a;
        border-right: none;
    }
  </style>