<template>
    <div>
      <InputPhoneNumber
      :name="name"
      v-model="phoneNumber"
      v-validate="rules"
      :data-vv-scope="scope"
      :error-text="errors.first(name) || getMsgErr(errors, name)"
      :error-class="errors.first(name) || getMsgErr(errors, name)"
      :isCountryComponent="isCountryComponent"
      @sweetAlertAction="showAlertAction"
      ></InputPhoneNumber>
    </div>
  </template>
  
  <script>
  import InputPhoneNumber from './InputPhoneNumber.vue';
  import VeeValidate from 'vee-validate';

  VeeValidate.setMode('passive');
  import SweetAlertTemplate from './SweetAlert.vue';
  const SweetAlert = Vue.extend(SweetAlertTemplate);

  export default {
    inject: ["$validator"],
    props: {
        name: { type: String,default: 'No Telepon'},
        scope: { type: String,default: null},
        data: { type: String },
        isValidate: { type: Boolean },
        phonePatient: {type: Boolean, default: false},
        isCountryComponent: {type: Boolean, default: false}
    },
    data() {
        return {
            phoneNumber: '',
            rules: {
                required: false,
                numeric:true,
                regex: /^[0-9]{8,16}$/
            }
        }
    },
    created() {
        this.rules.required = this.isValidate
    },
    watch: {
        data: function(val){
            this.phoneNumber = val
        },
        phoneNumber: function (val) {
            this.$emit("update:changedValue", val);
        }
    },
    // $_veeValidate: {
    //     value () {
    //         return this.phoneNumber;
    //     },
    //     name () {
    //         return this.name 
    //     },
    // },
    components: {
        InputPhoneNumber
    },
    methods: {
        getMsgErr(err, name) {
            if(err.items.length > 0){
                const items = err.items
                const indexName = items.findIndex(_item => _item.field == name)
                if(indexName >= 0) return items[indexName].msg 
            }
            return ''
        },
        showAlertAction () {
            new SweetAlert().showInfo({
                swalTitle: "Info",
                swalText: '"Notifikasi (SMS) tidak akan terkirim ke nomor selain nomor Indonesia (selain +62)', // response.data.message,
                swalType: "info",
                onConfirmButton: function() {
                    // done
                }
            });
        }
    }
  }
  </script>
  